import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getImages(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('https://jsonplaceholder.typicode.com/photos', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
        })

    }
  },
}
