<template>
  <div>
    <b-card no-body>

      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Entries</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
            <b-button variant="primary">
              Add Product
            </b-button>
          </b-col>

          <!-- Search -->

        </b-row>
      </div>

      <div id="itemList">
      <b-table
        ref="refGalleryListTable"
        :items="getImages"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >

        <!-- Column: albumId -->
        <template #cell(thumbnailUrl)="data">
          <b-link class="font-weight-bold" :key="data.id">
            <img :src="data.thumbnailUrl" />
          </b-link>
        </template>

        <!-- Column: albumId -->
        <template #cell(url)="data">
          <b-link class="font-weight-bold" :key="data.id">
            TEST
          </b-link>
        </template>


      </b-table>
      </div>

      <!-- PAGINATIO  -->
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalGalleries"
              :per-page="perPage"

              :link-gen="currentPage" 
              :number-of-pages="totalGalleries"

              aria-controls="itemList"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
      <!-- END PAGINATION -->

    </b-card>
    
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BCardText,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import { onUnmounted } from "@vue/composition-api";
import store from "@/store";
import useGallaryList from "./useGallaryList";
import gallaryStoreModule from "./gallaryStoreModule";

export default {
  components: {
    BCardText,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,

    vSelect,
  },
  setup() {
    const GALLERY_APP_STORE_MODULE_NAME = "app-gallery";

    // Register module
    if (!store.hasModule(GALLERY_APP_STORE_MODULE_NAME))
      store.registerModule(GALLERY_APP_STORE_MODULE_NAME, gallaryStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(GALLERY_APP_STORE_MODULE_NAME))
        store.unregisterModule(GALLERY_APP_STORE_MODULE_NAME);
    });

    // const statusOptions = [
    //   "Downloaded",
    //   "Draft",
    //   "Paid",
    //   "Partial Payment",
    //   "Past Due",
    // ];

    const {
      getImages,
      tableColumns,
      refGalleryListTable,
      perPage,
      currentPage,
      totalGalleries,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      statusFilter,

      refetchData,
    //   resolveGalleryStatusVariantAndIcon,
    //   resolveClientAvatarVariant,
    } = useGallaryList();

    console.log("UI Data: "+getImages)

    return {
      getImages,
      tableColumns,
      perPage,
      currentPage,
      totalGalleries,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refGalleryListTable,

      statusFilter,

      refetchData,
      avatarText,
    //   resolveGalleryStatusVariantAndIcon,
    //   resolveClientAvatarVariant,
    };
  },
  data() {
    return {
    //   products: [],
    //   fields: [
    //     "Gallery_Id",
    //     "Gallery_AlbumId",
    //     "Gallery_Title",
    //     "Gallery_Image",
    //     "Gallery_Logo",
    //     { key: "actions", label: "Actions" },
    //   ],
    //   items: [],
    };
  },
  methods: {
    edit(item, index, target) {
      console.log(item, index, target);
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>