import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// Data
//import { invoiceTableColumns } from '@/JsonsLayout/invoice'

export default function useGallaryList() {
  // Use toast
  const toast = useToast()

  const refGalleryListTable = ref(null)

  // Table Handlers
  //const tableColumns = invoiceTableColumns;

  const tableColumns = [
    { key: 'albumId', label: '#albumId', sortable: true },
    { key: 'id', label: "ID" },
    { key: 'title', label: "Title" },
    { key: 'thumbnailUrl', label: "Image" },
    { key: 'url', label: "Icon" },
    // { key: 'actions', label: "Actions" },
  ]

  const perPage = ref(10)
  const totalGalleries = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refGalleryListTable.value ? 
    refGalleryListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalGalleries.value,
    }
  })

  const refetchData = () => {
    refGalleryListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData()
  })

  const getImages = (ctx, callback) => {
    store
      .dispatch('app-gallery/getImages', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status: statusFilter.value,
      })
      .then(response => {
        console.log('data_response: ', response.data);
        //const { data, total } = response.data

        callback(response.data)
        //callback(data)
        totalGalleries.value = response.data.length;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching invoices' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

//   const resolveGalleryStatusVariantAndIcon = status => {
//     if (status === 'Partial Payment') return { variant: 'warning', icon: 'PieChartIcon' }
//     if (status === 'Paid') return { variant: 'success', icon: 'CheckCircleIcon' }
//     if (status === 'Downloaded') return { variant: 'info', icon: 'ArrowDownCircleIcon' }
//     if (status === 'Draft') return { variant: 'primary', icon: 'SaveIcon' }
//     if (status === 'Sent') return { variant: 'secondary', icon: 'SendIcon' }
//     if (status === 'Past Due') return { variant: 'danger', icon: 'InfoIcon' }
//     return { variant: 'secondary', icon: 'XIcon' }
//   }

//   const resolveClientAvatarVariant = status => {
//     if (status === 'Partial Payment') return 'primary'
//     if (status === 'Paid') return 'danger'
//     if (status === 'Downloaded') return 'secondary'
//     if (status === 'Draft') return 'warning'
//     if (status === 'Sent') return 'info'
//     if (status === 'Past Due') return 'success'
//     return 'primary'
//   }

  return {
    getImages,
    tableColumns,
    perPage,
    currentPage,
    totalGalleries,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refGalleryListTable,
    statusFilter,
    // resolveGalleryStatusVariantAndIcon,
    // resolveClientAvatarVariant,
    refetchData,
  }
}
